<template>
    <div>
        <b-modal ref="setor-form-config" size="lg"
                 hide-footer centered fixed scrollable
                 title="Configurações de email padrão">
            <b-container fluid>
                <b-row class="border mx-1 mt-3 py-2">
                    <b-col cols="6">
                        <input-select label="Habilitar E-mail de SAC" :opcoes="opcoes" v-model="habilitarEmailSac" />
                    </b-col>
                    <br />
                    <br />
                    <div class="ml-3 mt-3" v-if="habilitarEmailSac == 'Sim'">

                        <input type="radio" class="btn-check d-none" name="options" id="picked_email_padrao" v-model="picked_email" autocomplete="off" value="padrao">
                        <label class="btn btn-primary mr-2" for="picked_email_padrao">
                            <i class="far fa-envelope-open">&nbsp;&nbsp;</i>SMTP padrao
                        </label>

                        <input type="radio" class="btn-check d-none " name="options" id="picked_email_gmail" v-model="picked_email" autocomplete="off" value="gmail">
                        <label class="btn btn-primary mr-2" for="picked_email_gmail" @click="logarGmail">
                            <i class="fab fa-google">&nbsp;&nbsp;</i>Gmail
                        </label>

                        <input type="radio" class="btn-check d-none" name="options" id="picked_email_outlook" v-model="picked_email" autocomplete="off" disabled value="outlook">
                        <label class="btn btn-primary mr-2" for="picked_email_outlook" style="opacity: .3;">
                            <i class="fab fa-windows">&nbsp;&nbsp;</i> Outlook
                        </label>
                    </div>
                </b-row>

                <b-row class="border mx-1 mt-3 py-2" style="padding:20px" v-if="picked_email == 'padrao'">
                    <hr />
                    <h5 class="mt-3">
                        Configurações de E-mail (Recebimento)
                        <i class="fas fa-file-export float-right imap-forms-config"
                           title="Configurar recebimento de formulário"
                           @click="abrirModalImapFormsConfig"></i>
                        <i class="fas fa-user-slash float-right imap-forms-config"
                           style="margin-right:15px"
                           title="Lista de bloqueio de email"
                           @click="abrirBloqueioEmail"></i>
                    </h5>
                    <b-row cols="2">
                        <b-col class="mb-2">
                            <label id="emailSac">Email (para recebimento)</label>
                            <input v-model="emailSac" class="inputEmail" type="text" />
                        </b-col>
                        <b-col class="mb-2">
                            <label>Senha email</label>
                            <input v-model="senhaSac" class="inputEmail" type="password" />

                        </b-col>
                        <b-col class="mb-2">
                            <label>Endereço servidor IMAP</label>
                            <input v-model="enderecoImap" class="inputEmail" type="text" />

                        </b-col>
                        <b-col class="mb-2">
                            <label>Porta servidor IMAP</label>
                            <input v-model="portaEmail" class="inputEmail" type="text" />
                        </b-col>
                    </b-row>

                    <h5 class="mt-3">Configurações de E-mail (Envio)</h5>
                    <b-row cols="2">
                        <b-col class="mb-2">
                            <label>Email (para envio)</label>
                            <input v-model="emailSmtp" class="inputEmail" type="text" />
                        </b-col>
                        <b-col class="mb-2">
                            <label>Senha email</label>
                            <input v-model="senhaSmtp" class="inputEmail" type="password" />
                        </b-col>
                        <b-col class="mb-2">
                            <label>Endereço servidor SMTP</label>
                            <input v-model="enderecoSmtp" class="inputEmail" type="text" />
                        </b-col>
                        <b-col class="mb-2">
                            <label>Porta servidor SMTP</label>
                            <input v-model="portaSmtp" class="inputEmail" type="number" />
                        </b-col>
                        <b-col class="mb-2">
                            <label>Habilitar SSL</label>
                            <select v-model="habilitarSsl">
                                <option disabled :selected="habilitarSsl === null" value="null">Selecione o setor</option>
                                <option v-for="item in opcoes" :value="item.value" :selected="habilitarSsl === item.value">{{ item.text}}</option>
                            </select>
                        </b-col>
                        <b-col class="mb-2">
                            <label>Habilitar credenciais de E-mail</label>
                            <select v-model="habilitarCredenciaisEmail">
                                <option disabled :selected="habilitarCredenciaisEmail === null" value="null">Selecione o setor</option>
                                <option v-for="item in opcoes" :value="item.value" :selected="habilitarCredenciaisEmail === item.value">{{ item.text }}</option>
                            </select>
                        </b-col>
                    </b-row>

                </b-row>


                <!--Configurações para WhatsBusiness-->

            </b-container>
            <div class="w-100 text-right mt-4">
                <botao-modal-cancel class="d-inline mr-2"  @click="fecharModal">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvarConfigEmailSetor">Salvar</botao-modal-ok>
            </div>
        </b-modal>


    </div>
</template>

<script>

    import omniApiHost from "../../config/host.js";
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import ModalIMAPFormsConfig from "@/components/common/ModalIMAPFormsConfig";
    import ModalBloqueioEmail from "@/components/setores/ModalBloqueioEmail";
    import axios from 'axios'
    export default {
        name: 'UsuarioFormPopup',
        components: {
            InputPadrao,
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel,
            ModalIMAPFormsConfig,
            ModalBloqueioEmail
        },


        data() {
            return {
                id: '',
                emailSac: '',
                senhaSac: '',
                enderecoImap: '',
                portaEmail: 0,
                remetentesEmailFormulario: null,
                templateEmailFormulario: null,
                emailSmtp: '',
                senhaSmtp: '',
                enderecoSmtp: '',
                portaSmtp: 0,
                habilitarSsl: 'Não',
                habilitarCredenciaisEmail: 'Não',
                opcoes: [{ text: 'Sim', value: 'Sim' }, { text: 'Não', value: 'Não' }],
                options: [{ text: 'SHARKDATA', value: 'SHARKDATA' }],
                clickSalvar: false,
                emailsBloquedos: null,
                setorDados: null,
                habilitarEmailSac: 'Não',
                picked_email: null,

            }
        },

        methods: {
            salvarConfigEmailSetor() {
                console.log("aadasdas", this.picked_email)
                if (this.picked_email == 'padrao') {
                    let data = {
                        SetorId: this.id,
                        EmailSAC: this.emailSac,
                        EderecoImap: this.enderecoImap,
                        EmailSMTP: this.emailSmtp,
                        EnderecoSMTP: this.enderecoSmtp,
                        SenhaSAC: this.senhaSac,
                        SenhaSMTP: this.senhaSmtp,
                        PortaSMTP: this.portaSmtp,
                        PortaEmail: this.portaEmail,
                        HabilitarCredenciaisEmail: this.habilitarCredenciaisEmail,
                        HabilitarSSL: this.habilitarSsl,
                        Provedor: 0
                    }
                    axios.put('/api/setor/AlterarSetorEmail', data)
                        .then((response) => {
                            console.log("ret ", response)
                            $("#caixa_carregamento").hide();
                            this.$emit('atualizar')
                        }, (error) => {
                            console.log(error);
                            $("#caixa_carregamento").hide();
                        });
                }
                this.$emit('atualizar');
                this.fecharModal()

            },

            showModal(setor) {
                //  this.limparFormulario();
                this.setorDados = setor;
                this.id = setor.Id;

                this.emailSac = setor.EmailSAC;
                this.emailSmtp = setor.EmailSMTP;
                this.enderecoImap = setor.EderecoImap;
                this.enderecoSmtp = setor.EnderecoSMTP;
                this.senhaSac = setor.SenhaSAC;
                this.senhaSmtp = setor.SenhaSMTP;
                this.portaSmtp = setor.PortaSMTP;
                this.portaEmail = setor.PortaEmail;

                this.remetentesEmailFormulario = setor.RemetentesEmailFormulario;
                this.templateEmailFormulario = setor.TemplateEmailFormulario;
                this.habilitarCredenciaisEmail = setor.HabilitarCredenciaisEmail;
                this.habilitarEmailSac = setor.HabilitarEmailSAC;
                this.habilitarSsl = setor.HabilitarSSL;
                this.$refs['setor-form-config'].show()
            },
            abrirBloqueioEmail() {
                this.$refs["email-bloqueio"]
                    .open(this.emailsBloquedos);
            },
            abrirModalImapFormsConfig() {
                this.$refs["imap-forms-config"]
                    .open(this.remetentesEmailFormulario, this.templateEmailFormulario)
                    .then(data => {
                        this.remetentesEmailFormulario = data.remetentes;
                        this.templateEmailFormulario = data.template;
                    });
            },
            
            fecharModal: function () {
                this.$refs['setor-form-config'].hide()
            },
            logarGmail() {
                let url = "https://accounts.google.com/o/oauth2/auth?scope=https://mail.google.com&response_type=code&access_type=offline&redirect_uri=" + omniApiHost + "/api/apigoogle/code&client_id=109573207931-kl56fu6vhhou0q4rm9l2mhsd5pbvn7o7.apps.googleusercontent.com&state=" + this.id;
                window.open(url, "_blank");
            }
        },
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
        margin: 1px 0 0 0;
    }

    .imap-forms-config {
        cursor: pointer;
        opacity: .5;
        transition: all ease-in-out .3s;
    }

        .imap-forms-config:hover {
            opacity: 1;
        }


    .inputEmail {
        background-color: var(--cinza-1);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
        height: 35px;
        font-size: 15px;
        margin-bottom: 2vh;
    }

        .inputEmail:hover, .inputEmail:focus {
            outline: none !important;
        }

    .styles-inputs input, select {
        background-color: var(--cinza-1) !important;
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        width: 100%;
        margin-bottom: calc(2vh + 12px);
    }
</style>