<template>
    <b-modal id="email-bloqueio" centered title="Configurar bloqueios de emails">
        <b-container>
            <b-row>
                <b-col class="mb-3">
                    <div>
                        <b-form-textarea id="textarea"
                                         v-model="bloqueados"
                                         rows="3"
                                         max-rows="6">
                        </b-form-textarea>
                    </div>
                    <br>
                    <div style="color:#666;font-size:12px;">
                        <i>
                            <b>Obs.:</b>
                            Cada email a ser bloquedo deve ser inserido em linhas separadas.
                            <br />
                        </i>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <template #modal-footer="{ cancel }">
            <b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>
            <b-button variant="success"
                      @click="confirm"
                      class="rounded-0">
                Confirmar
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "ModalBloqueioEmail",
        props: {
            SetorId: null,
            EmailsBloquedos: null
        },
        data() {
            return {
                bloqueados: "",

            };
        },

        methods: {
            open(emailsBloc) {
                this.$nextTick(() => {
                    this.$bvModal.show("email-bloqueio");
                });
                this.buscaSetor();
            },
            buscaSetor() {
                axios.get('/api/setor/' + this.SetorId)
                    .then((response) => {
                        this.bloqueados = response.data.EmailsBloqueados;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            confirm() {
                $("#caixa_carregamento").show();
                var data = {
                    "Id": this.SetorId,
                    "EmailsBloqueados": this.bloqueados
                };
                axios.put('/api/setor/SalvarListaEmailsBloqueados', data)
                    .then((response) => {
                        this.bloqueados = response.data;
                        this.$bvModal.hide("email-bloqueio");
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
        }
    }
</script>

<style scoped>
    label {
        display: block;
        font-weight: bold;
        font-size: 12px;
        margin: 0;
        white-space: nowrap;
    }

    textarea {
        background-color: var(--cinza-2);
        border-color: var(--cinza-4);
        color: var(--cinza-5);
        outline-style: none;
        margin: 0;
        transition: all ease-in-out .3s;
        font-size: 12px;
        resize: none;
        padding: 2px 5px;
    }

        textarea + i {
            display: block;
            font-size: 12px;
            color: var(--cinza-5);
            margin: 0;
            cursor: default;
        }

        textarea:focus {
            filter: brightness(1.03);
        }

    ul {
        list-style-type: none;
        padding-left: 0;
        cursor: default;
    }

        ul > li {
            padding: 2px 3px;
            font-size: 12px;
            border-bottom: 1px #ccc solid;
        }

            ul > li:hover {
                background-color: var(--cinza-1);
            }

            ul > li > b {
                user-select: none;
            }

    #remetentes {
        text-transform: lowercase;
    }

    #template {
        height: 300px;
    }

    #variaveis {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
    }
</style>