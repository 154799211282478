<template>
	<b-modal id="imap-forms-config" size="xl" centered title="Configurar recebimento de formulário" @hide="reset">
		<b-container>
			<b-row>
				<b-col class="mb-3">
					<label for="remetentes">Remetentes:</label>
					<textarea id="remetentes" v-model="remetentes" class="w-100 app-scroll-custom"></textarea>
					<i>Lista de e-mails separados por quebra de linha ou asterisco (*) para qualquer remetente.</i>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="3">
					<label>Variáveis extraíveis:</label>
					<ul>
						<li v-for="key in Object.keys(variaveis)">
							<b class="text-info">{{variaveis[key]}}: </b>
							<span class="text-secondary float-right">{{key}}</span>
						</li>
					</ul>
				</b-col>
				<b-col cols="9">
					<label for="template">Modelo para extração de dados:</label>
					<textarea id="template" v-model="template" class="w-100 app-scroll-custom"></textarea>
					<i>
						Representação exata (incluindo HTML) do trecho do e-mail com os dados do formulário sinalizados por variáveis.
						<br />
						<b>Obs.:</b>
						Sinalize também as variáveis não extraíveis para garantir o reconhecimento do modelo, e.g.: 
						<b v-pre>{{Mensagem}}</b>,
						<b v-pre>{{DataEnvio}}</b>,
						<b v-pre>{{Autor}}</b>,
						<b v-pre>{{Etc}}</b>.
					</i>
				</b-col>
			</b-row>
		</b-container>
		<template #modal-footer="{ cancel }">
			<b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>
			<b-button variant="success"
					  @click="confirm"
					  class="rounded-0">
				Confirmar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: "ModalIMAPFormsConfig",
		data() {
			return {
				variaveis: {
					"{{RazaoSocial}}": "Razão Social",
					"{{NomeFantasia}}": "Nome Fantasia",
					"{{PrimeiroNome}}": "Primeiro Nome",
					"{{UltimoNome}}": "Último Nome",
					"{{Telefone}}": "Telefone",
					"{{Email}}": "E-mail",
					"{{Endereco}}": "Endereço",
					"{{EnderecoNumero}}": "Número",
					"{{Complemento}}": "Complemento",
					"{{Bairro}}": "Bairro",
					"{{Municipio}}": "Município",
					"{{UF}}": "UF",
					"{{Cep}}": "CEP"
				},
				remetentes: null,
				template: null,
				resolve: null
			};
		},
		methods: {
			open(remetentes, template) {
				this.remetentes = remetentes;
				this.template = template;
				this.$bvModal.show("imap-forms-config");
				return new Promise(resolve => {
					this.resolve = resolve;
				});
			},
			confirm() {
				this.resolve({
					remetentes: (this.remetentes ?? "").trim() ? this.remetentes.toLowerCase() : null,
					template: (this.template ?? "").trim() ? this.template : null
				});
				this.$bvModal.hide("imap-forms-config");
			},
			reset() {
				this.remetentes = null;
				this.template = null;
				this.resolve = null;
			}
		}
	}
</script>

<style scoped>
	label {
		display: block;
		font-weight: bold;
		font-size: 12px;
		margin: 0;
		white-space: nowrap;
	}

	textarea {
		background-color: var(--cinza-2);
		border-color: var(--cinza-4);
		color: var(--cinza-5);
		outline-style: none;
		margin: 0;
		transition: all ease-in-out .3s;
		font-size: 12px;
		resize: none;
		padding: 2px 5px;
	}

		textarea + i {
			display: block;
			font-size: 12px;
			color: var(--cinza-5);
			margin: 0;
			cursor: default;
		}

		textarea:focus {
			filter: brightness(1.03);
		}

	ul {
		list-style-type: none;
		padding-left: 0;
		cursor: default;
	}

		ul > li {
			padding: 2px 3px;
			font-size: 12px;
			border-bottom: 1px #ccc solid;
		}

			ul > li:hover {
				background-color: var(--cinza-1);
			}

			ul > li > b {
				user-select: none;
			}

	#remetentes {
		text-transform: lowercase;
	}

	#template {
		height: 300px;
	}

	#variaveis {
		color: blue;
		cursor: pointer;
		text-decoration: underline;
	}
</style>